import { useIntl, MessageDescriptor } from 'react-intl'
import { useRouter } from 'next/router'
import { useDomainContext } from 'context/DomainContext'
// Disabling restricted imports as we use next-seo (see Seo.tsx)
/* eslint-disable no-restricted-imports */
import { NextSeoProps } from 'next-seo'
import { OpenGraphMedia } from 'next-seo/lib/types'

type MessageInput = {
  message: MessageDescriptor
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: Record<string, any>
}

type SeoInput = {
  titleMessage?: MessageInput
  descriptionMessage?: MessageInput
  overrides?: {
    title?: string
    description?: string
    ogTitle?: string
    ogDescription?: string
    noindex?: boolean
    images?: ReadonlyArray<OpenGraphMedia>
  }
}

const useSeo = ({
  titleMessage,
  descriptionMessage,
  overrides,
}: SeoInput): NextSeoProps => {
  const intl = useIntl()
  const { asPath, locale } = useRouter()
  const contextDomain = useDomainContext()
  const noindex = overrides?.noindex
  const images = overrides?.images

  const title = titleMessage
    ? (intl.formatMessage(
        titleMessage?.message,
        titleMessage?.values
      ) as string)
    : ''

  const description = descriptionMessage
    ? (intl.formatMessage(
        descriptionMessage?.message,
        descriptionMessage?.values
      ) as string)
    : ''

  const currentUrl = `${contextDomain}${asPath}`

  const hrefLang: NextSeoProps['languageAlternates'] = locale
    ? [{ hrefLang: locale, href: currentUrl }]
    : undefined

  const titleToUse = overrides?.title ?? title
  const descriptionToUse = overrides?.description ?? description

  return {
    title: titleToUse,
    description: descriptionToUse,
    canonical: currentUrl,
    openGraph: {
      type: 'website',
      url: currentUrl,
      title: overrides?.ogTitle ?? titleToUse,
      description: overrides?.ogDescription ?? descriptionToUse,
      images,
    },
    noindex,
    languageAlternates: hrefLang,
  }
}

export type { SeoInput }
export default useSeo
